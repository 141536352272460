import API from './api';
import store from '../store';

export default {
    signOut: () => API.delete('adminuser/signout'),

    signIn: (email, password) => API.post('/adminuser/signin', {
        email,
        password
    }),

    getInfo: userId => API.get(`/adminuser/${userId}`),

    getAdmins: () => API.get('/adminuser/'),
}