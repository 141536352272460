import API from './api';

export default {
    signOut: () => API.delete('user/signout'),

    signUp: newUser => API.post('/user/signup', {
        first_name: newUser.first_name,
        last_name: newUser.last_name,
        email: newUser.email,
        password: newUser.password,
        birthdate: newUser.birthdate,
        role_id: newUser.role_id,
        specialty_id: newUser.specialty_id,
        education_id: newUser.education_id,
        sex_id: newUser.sex_id,
        district: newUser.district,
        city: newUser.city,
        state: newUser.state,
        country: newUser.country
    }),

    signIn: (email, password) => API.post('/user/signin', {
        email,
        password
    }),

    confirm: (code, signupToken) => API.post('/user/confirm', { code }, {
        headers: {
            'x-signup-token': signupToken
        }
    }),

    resendCode: (email, password) => API.post('/user/resend-code', {
        email,
        password
    }),

    recoverPassword: email => API.post('/user/recover-password', {
        email
    }),
    
    updatePassword: (
        userId, 
        recoverCode, 
        newPassword, 
        recoverToken
    ) => API.post('/user/update-password', {
        user_id: userId,
        recover_code: recoverCode,
        new_password: newPassword
    }, {
        headers: {
            'x-recover-token': recoverToken
        }
    }),

    getRoles: () => API.get('/user/roles'),

    getEducations: () => API.get('/user/educations'),

    getSpecialties: () => API.get('/user/specialties'),

    addRole: ({ name, description }) => API.post('/user/roles', {
        name,
        description
        }),

    addEducation: ({ description }) => API.post('/user/educations', { description }),

    addSpecialty: ({ name, description }) => API.post('/user/specialties', {
        name,
        description
    }),

    removeRole: roleId => API.delete(`/user/roles/${roleId}`),

    removeEducation: educationId => API.delete(`/user/educations/${educationId}`),

    removeSpecialty: specialtyId => API.delete(`/user/specialties/${specialtyId}`),

    getInfo: userId => API.get(`/user/${userId}`),

    getUsers: (page=1) => {
        return API.get('/user/', {
            params: {
                page
            }
        })
    },

    updateInfo: (userId, updatedUserData) => API.put(`user/${userId}`, updatedUserData),

    removeUser: userId => API.delete(`user/${userId}`)
}