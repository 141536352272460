import React from 'react';
import SigninForm from './signin-form';
import { Col, Row } from 'antd';

const SigninPage = () => {
    return (
        <Row type='flex' align='middle' className='h-100'>
            <Col span={8} offset={8}>
                <SigninForm />
            </Col>
        </Row>
    );
}
 
export default SigninPage;