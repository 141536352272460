import API from './api';

export default {
    get: tagId => API.get(`/tag/${tagId}`),
    getAll: () => API.get('/tag'),
    create(data) {
        return API.post(`/tag`, data)
    },
    update(tagId, data) {
        return API.put(`/tag/${tagId}`, data)
    },
    remove(tagId) {
        return API.delete(`/tag/${tagId}`)
    } 
}