import API from './api';
export default {
    get: newsId => API.get(`/news/${newsId}`),
    getAll: (page=1) => API.get('/news', {
        params: {
            page
        }
    }),
    update: (newsId, updates) => API.put(`/news/${newsId}`, updates),
    create: data => API.post(`/news`, data),
    search: (query, page=1) => API.get('/news/search', {
        params: {
            q: query,
            page
        }
    }),
    remove: newsId => API.delete(`/news/${newsId}`)
}