import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message, Layout, Table, Popconfirm, Button, Icon, Divider } from "antd";
import TagService from '../../../../services/Tag';
const { Content, Header } = Layout;

const TagsPage = () => {
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);

    function fetchTags() {
        setLoading(true);
        TagService.getAll()
        .then(response => {
            setLoading(false);
            setTags(response.data.tags);
        });
    }

    function removeTag(tagId) {
        const cancelLoading = message.loading('Removendo tag...');
        TagService.remove(tagId)
        .then(() => {
            cancelLoading();
            fetchTags();
            message.success('Tag removida com sucesso.');
        })
        .finally(() => {
            cancelLoading();
        });
    };

    useEffect(() => {
        fetchTags();
    }, []);

    return (
        <Content className="ps-admin-content">
            <Header className="pt-3">
                <h3 className="h5 d-block">Tags</h3>
                <Link className="d-block" to="/tags/adicionar">
                    <Button type="primary">
                        Adicionar
                        <Icon type="plus" />
                    </Button>
                </Link>
            </Header>
            <Table
                loading={loading}
                pagination={false}
                dataSource={tags}
                columns={[{
                    title: 'Nome',
                    dataIndex: 'name',
                    key: 'name',
                }, {
                    title: 'Descrição',
                    dataIndex: 'description',
                    key: 'description',
                }, {
                  title: 'Ações',
                  key: 'action',
                  render: (text, item) => (
                    <span>
                        <Link to={`/tags/${item.id}`}>
                            <Button type='link'>Editar</Button>
                        </Link>
                        <Divider type='vertical' />
                        <Popconfirm
                            title="Deseja mesmo remover essa tag?"
                            onConfirm={() => removeTag(item.id)}
                            okText="Sim"
                            cancelText="Não"
                        >
                            <Button type='link'>Remover</Button>
                        </Popconfirm>
                    </span>
                  )
                }]}
            />
        </Content>
    );
};

export default TagsPage;