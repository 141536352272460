import React, { useEffect,useState } from 'react';
import { Card, Icon, Button, List, message } from "antd";
import { Link } from 'react-router-dom';

const AdminOption = props => {
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        console.log(props.responseDataField)
        props.listData
        .then(({ data }) => {
            setList(data[props.responseDataField].slice(0, 5));
            setTotal(data.total_items || data[props.responseDataField].length);
        });
    }, [props.listData, props.responseDataField]);

    return (
        <Card
            className="ps-admin-option"
            hoverable
            title={(
                <div className="ps-admin-option-title">
                    <span>
                        <span className="ps-admin-option-icon">
                            <Icon type={props.icon} />
                        </span>
                        {props.title}
                    </span>
                    {
                        props.canAdd && (
                            <Link to={props.addRoute} >
                                <Button type="primary">
                                    <Icon type="plus" />
                                </Button>
                            </Link>
                        )
                    }
                </div>
            )}
            ordered={false}
        >
    
            <List
                className="ps-admin-option-list"
                itemLayout="vertical"
                dataSource={list}
                size="small"
                renderItem={props.listItemRender}
            />
            <div className="text-center">
                ...
            </div>
            <div className="ps-admin-option-footer">
                <span>
                    {props.info(total)}
                </span>
                <Link to={props.route}>
                    <Button type="primary" ghost>
                        Visualizar
                    </Button>
                </Link>
            </div>
        </Card>
    );
}

export default AdminOption;