import API from './api';

export default {
    get: quizId => API.get(`/quiz/${quizId}`),
    getAll: (page=1) => API.get('/quiz', {
        params: { page }
    }),
    create(data) {
        return API.post(`/quiz`, data)
    },
    update(quizId, data) {
        return API.put(`/quiz/${quizId}`, data)
    },
    updateQuestion(questionId, quizId, data) {
        return API.put(`/quiz/${quizId}/question/${questionId}`, data)
    },
    remove(quizId) {
        return API.delete(`/quiz/${quizId}`)
    },
    removeQuestion(quizId, questionId) {
        return API.delete(`/quiz/${quizId}/question/${questionId}`)
    } 
}