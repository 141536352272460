import React from 'react';
import { Layout, message, Form } from "antd";
import { useHistory } from 'react-router';
import NewsService from '../../../../services/News';
import NewsForm from '../../../general/news-form';

const { Header, Content } = Layout;

const AddNewsPage = () => {
    const history = useHistory();
    
    const handleSubmit = values => {
        const cancelLoading = message.loading('Criando notícia...');

        NewsService.create(values)
        .then(() => {
            message.success('Notícia criada com sucesso.');
            history.push('/noticias');
        })

        .finally(() => {
            cancelLoading();
        });
    };

    return (
        <Layout className="ps-admin-content">
            <Header>
                <span>Crie uma nova notícia</span>
            </Header>
            <Content>
                <div className="pd-add-news-container">
                    <NewsForm onSubmit={handleSubmit} loading={false}  />
                </div>
            </Content>
        </Layout>
    );
}
const WrappedEditNewsForm = Form.create({ name: 'register' })(AddNewsPage);

export default WrappedEditNewsForm;


