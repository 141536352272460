import { SET_USER_PROPS, SET_ACCESS_TOKEN } from '../actions/types';

const initialState = {
    user: {
        id: null,
        first_name: null,
        last_name: null,
        email: null
    },
    access_token: ''
};

const signinReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_USER_PROPS:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload
                }
            };
        case SET_ACCESS_TOKEN:
            return {
                ...state,
                access_token: action.payload
            };
        default:
            return state;
    }
};

export default signinReducer;