import React, { Component } from 'react';
import { Form, Icon, Input, Button, Checkbox, message } from 'antd';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import LogoImage from '../../../../assets/logo.png';
import { setAccessToken } from '../../../../actions';
import UserService from "../../../../services/Admin";


const SigninForm = Form.create({ name: 'sigin_form' })(
class extends Component {
  handleSubmit = e => {
    e.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (!err) {
        UserService.signIn(values.username, values.password)
        .then(response => {
            const { data } = response;
            this.props.setAccessToken(data.access_token);
            if (values.remember) {
                localStorage.setItem('auth-access-token', data.access_token);
            } else {
                localStorage.removeItem('auth-access-token');
            }

            this.props.history.push('/');
        });
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
        <div>
            <img alt="EducaSangue Logo" className="d-block mx-auto mb-4" src={LogoImage} width={160} />
            <Form onSubmit={this.handleSubmit} className="signin-form">
                <Form.Item>
                    {getFieldDecorator('username', {
                        rules: [{ required: true, message: 'Por favor, insira seu email!' }]
                    })(
                        <Input
                            prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            placeholder="Email"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('password', {
                        rules: [{ required: true, message: 'Por favor, insira sua senha!' }]
                    })(
                        <Input
                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                            type="password"
                            placeholder="Senha"
                        />
                    )}
                </Form.Item>
                <Form.Item>
                    {getFieldDecorator('remember', {
                        valuePropName: 'checked',
                        initialValue: true
                    })(<Checkbox>Manter sessão</Checkbox>)}
                    <Button type="primary" htmlType="submit" className="signin-form-button float-right mt-1">
                        Entrar
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
  }
});

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ setAccessToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
    withRouter(SigninForm)
);