import React from 'react';
import { Layout, message, Form } from "antd";
import { useHistory } from 'react-router';
import TagService from '../../../../services/Tag';
import TagForm from '../../../general/tag-form';

const { Header, Content } = Layout;

const AddTagPage = () => {
    const history = useHistory();
    
    const handleSubmit = values => {
        const cancelLoading = message.loading('Criando tag...');

        TagService.create(values)
        .then(() => {
            message.success('Tag criada com sucesso.');
            history.push('/tags');
        })

        .finally(() => {
            cancelLoading();
        });
    };

    return (
        <Layout className="ps-admin-content">
            <Header>
                <span>Crie uma nova tag</span>
            </Header>
            <Content>
                <div className="pd-add-tag-container">
                    <TagForm onSubmit={handleSubmit} loading={false}  />
                </div>
            </Content>
        </Layout>
    );
}
const WrappedEditTagForm = Form.create({ name: 'tag_register' })(AddTagPage);

export default WrappedEditTagForm;


