import React, { Component, useEffect, useRef, useState } from 'react';
import { Layout, Button, Icon, Form, Input, Collapse, Radio, InputNumber, message, Popconfirm  } from "antd";
import QuizService from '../../../../services/Quiz';
import './edit-quiz.scss';
import { useParams } from 'react-router';

const { Content, Header } = Layout;
const { Panel } = Collapse;

const EditQuizFrom = props => {
    const id = useRef()
    const [loading, setLoading] = useState(true);
    const [currentQuiz, setCurrentQuiz] = useState({});
    const { quizId } = useParams();

    useEffect(() => {
        id.current = 0
    }, [])

    useEffect(() => {
        const cancelLoading = message.loading('Carregando tag...');
        QuizService.get(quizId)
        .then(({ data }) => {
            setCurrentQuiz(data.quiz);
        })
        .finally(() => {
            cancelLoading();
            setLoading(false);
        });
    }, [quizId])

    const remove = (k) => {
        const { form } = props;
        const keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }

        function internalRemove() {
            setCurrentQuiz(currentQuiz => {
                currentQuiz.questions = currentQuiz.questions.filter(q => q.id !== k)
                return currentQuiz
            })
            form.setFieldsValue({
                keys: keys.filter(key => key !== k),
            });
        }

        if (currentQuiz && currentQuiz.questions.find(q => q.id === k)) {
            const cancelLoading = message.loading('Apagando questão...');
            QuizService.removeQuestion(quizId, k)
            .then(() => {
                internalRemove()
            })
            .finally(() => {
                cancelLoading();
                setLoading(false);
            });
        } else {
            internalRemove()
        }
    }

    const add = () => {
        const { form } = props;
        const keys = form.getFieldValue('keys');
        const nextKeys = keys.concat(id.current);
        id.current = id.current + 1;
        form.setFieldsValue({
            keys: nextKeys,
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        props.form.validateFields((err, values) => {
            if (!err) {
                const { keys } = values;
                if (keys.length === 0) {
                    message.error('O Quiz deve ter ao menos uma pergunta!');
                } else {
                    // Salvando novas questões
                    const oldQuestions = currentQuiz.questions;
                    const newQuestions = [];
                    const fields = ['question', 'item1', 'item2', 'item3', 'item4', 'correct', 'reference'];
                    keys
                    .forEach((id, i) => {
                        const oldQuestion = oldQuestions.find(q => q.id === id)
                        if (oldQuestion) {
                            fields.forEach(field => {
                                oldQuestion[field] = values[field][id] || oldQuestion[field];
                            });
                            oldQuestions[i] = oldQuestion;
                        } else {
                            const newQuestion = {}
                            fields.forEach(field => {
                                newQuestion[field] = values[field][id];
                            });
                            newQuestions.push(newQuestion)
                        }
                    });

                    for (const field of fields) {
                        delete values[field];
                    }

                    delete values['keys'];

                    const cancelLoading = message.loading('Salvando quiz...');

                    const requests = [
                        QuizService.update(quizId, { ...values, questions: newQuestions }),
                        ...oldQuestions.map(question => {
                            return QuizService.updateQuestion(question.id, quizId, { ...question, id: undefined })
                        })
                    ]

                    Promise.all(requests)
                    .then(() => {
                        message.success('Quiz salvo com sucesso!');
                    })
                    .finally(() => {
                        cancelLoading();
                    });
                }
            }
        });
    }

    const { getFieldDecorator, getFieldValue } = props.form;
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };
    const formItemLayoutWithOutLabel = {
        wrapperCol: {
            xs: { span: 24, offset: 0 },
            sm: { span: 20 },
        },
    };
    getFieldDecorator('keys', { initialValue: currentQuiz?.questions?.map(q => q.id) || [] });
    const keys = getFieldValue('keys');

    const formItems = keys.map((k, index) => (
        <div key={k} className="ps-quiz-question-form-container">
            <Collapse className="ps-quiz-question-form-collapser">
                <Panel header={`Questão ${index + 1}`}>
                    <Form.Item
                        {...formItemLayout}
                        label="Título"
                        required={false}
                    >
                        {getFieldDecorator(`question[${k}]`, {
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].question
                        })(
                            <Input placeholder="Título" style={{ width: '60%', marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="Referência"
                        required={false}
                    >
                        {getFieldDecorator(`reference[${k}]`, {
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].reference
                        })(
                            <Input placeholder="Referência" style={{ width: '60%', marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="Item 1"
                        required={false}
                    >
                        {getFieldDecorator(`item1[${k}]`, {
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].item1
                        })(
                            <Input style={{ width: '60%', marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="Item 2"
                        required={false}
                    >
                        {getFieldDecorator(`item2[${k}]`, {
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].item2
                        })(
                            <Input style={{ width: '60%', marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="Item 3"
                        required={false}
                    >
                        {getFieldDecorator(`item3[${k}]`, {
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].item3
                        })(
                            <Input style={{ width: '60%', marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="Item 4"
                        required={false}
                    >
                        {getFieldDecorator(`item4[${k}]`, {
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].item4
                        })(
                            <Input style={{ width: '60%', marginRight: 8 }} />
                        )}
                    </Form.Item>
                    <Form.Item
                        {...formItemLayout}
                        label="Item correto"
                        required={false}
                    >
                        {getFieldDecorator(`correct[${k}]`, {
                            rules: [{ required: true, message: 'Campo obrigatório' }],
                            initialValue: currentQuiz.questions && currentQuiz.questions[index] && currentQuiz.questions[index].correct
                        })(
                            <Radio.Group>
                                <Radio value={1}>item 1</Radio>
                                <Radio value={2}>item 2</Radio>
                                <Radio value={3}>item 3</Radio>
                                <Radio value={4}>item 4</Radio>
                            </Radio.Group>
                        )}
                    </Form.Item>
                </Panel>
            </Collapse>
            {
                keys.length > 1 && (
                    <Popconfirm
                        title="Confirma que deseja remover? Isso é irreversível."
                        onConfirm={() => remove(k)}
                        okText="Sim"
                        cancelText="Não"
                    >
                        <Icon
                            className="ps-question-delete-button"
                            type="close-circle"
                        />
                    </Popconfirm>
                )
            }
        </div>
    ));
    return (
        <Form onSubmit={handleSubmit}>
            <Form.Item {...formItemLayout} label="Título">
                {getFieldDecorator('name', {
                    rules: [{ required: true, message: 'Por favor, insira o título!' }],
                    initialValue: currentQuiz.name
                })(<Input />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Descrição">
                {getFieldDecorator('description', {
                    rules: [{ required: true, message: 'Insira a descrição!' }],
                    initialValue: currentQuiz.description
                })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Assunto principal">
                {getFieldDecorator('subject', {
                    rules: [{ required: true, message: 'Insira o assunto!' }],
                    initialValue: currentQuiz.subject
                })(<Input />)}
            </Form.Item>
            <Form.Item {...formItemLayout} label="Score">
                {getFieldDecorator('score', {
                    rules: [{ required: true, message: 'Insira o score!' }],
                    initialValue: currentQuiz.score
                })(<InputNumber min={0} />)}
            </Form.Item>
            {formItems}
            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="dashed" onClick={add} style={{ width: '60%' }}>
                    <Icon type="plus" /> Adicionar questão
                </Button>
            </Form.Item>
            <Form.Item {...formItemLayoutWithOutLabel}>
                <Button type="primary" htmlType="submit">Salvar</Button>
            </Form.Item>
        </Form>
    );
}

const WrappedEditQuizFrom = Form.create({ name: 'question_form_item' })(EditQuizFrom);

const AddQuizPage = () => {
    return (
        <Layout className="ps-admin-content">
            <Header>
                <span>Edição de quiz</span>
            </Header>
            <Content>
                <div className="pd-add-quiz-container">
                    <WrappedEditQuizFrom />
                </div>
            </Content>
        </Layout>
    );
}


export default AddQuizPage;
