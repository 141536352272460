export default {
    pages: [{
        label: 'Início',
        key: 'dashboard',
        icon: 'home',
        href: '/'
    }, {
        label: 'Quizzes',
        key: 'quizzes',
        icon: 'question',
        href: '/quizzes'
    }, {
        label: 'Notícias',
        key: 'news',
        icon: 'pic-left',
        href: '/noticias'
    }, {
        label: 'Tags',
        key: 'tags',
        icon: 'tag',
        href: '/tags'
    }, {
        label: 'Usuários',
        key: 'users',
        icon: 'user',
        href: '/usuarios',
        subitems: [{
            label: 'Profissões',
            key: 'roles',
            icon: 'idcard',
            href: '/usuarios/funcoes',
        }, {
            label: 'Especialidades',
            key: 'specialties',
            icon: 'reconciliation',
            href: '/usuarios/especialidades',
        }, {
            label: 'Escolaridades',
            key: 'education',
            icon: 'read',
            href: '/usuarios/escolaridades',
        }]
    }]
};