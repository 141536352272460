import axios from 'axios';
import { message } from 'antd';
import store from '../store';

let accessToken = localStorage.getItem('auth-access-token');

// export const baseUrlHost = 'http://localhost:5000';
export const baseUrlHost = process.env.REACT_APP_API_BASE_URL || '';

const API = axios.create({
    baseURL: `${baseUrlHost}/api/`
});


API.interceptors.request.use(function (config) {
    config = {
        ...config,
        headers: {
            ...config.headers,
            'x-access-token': store.getState().signinState.access_token || accessToken
        }
    }
    return config;
}, function (error) {
    return Promise.reject(error);
});

API.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response && error.response.data) {
        if (error.response.data.message) {
            message.error(error.response.data.message);
        } else {
            message.error('Houve um problema interno, tente novamente.');
        }
    }
    return Promise.reject(error);
});


export default API;