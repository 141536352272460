import React, { useEffect, useState } from 'react';
import { PageHeader, Icon, Layout } from "antd";
import { Switch, Route, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import MainMenu from '../../general/menu';
import HomePage from './home';
import NewsPage from './news';
import EditNewsPage from './news-edit';
import AddNewsPage from './news-add';
import QuizzesPage from './quizzes';
import AddQuizPage from './add-quiz';
import EditQuizPage from './edit-quiz';
import UsersPage from './users';
import RolesPage from './roles';
import EducationsPage from './educations';
import SpecialtiesPage from './specialties';
import AddRolePage from './roles-add';
import AddEducationPage from './educations-add';
import AddSpecialtyPage from './specialties-add';
import TagsPage from './tags';
import AddTagPage from './tags-add';
import EditTagPage from './tags-edit';
import UserService from '../../../services/User';
import { setAccessToken } from '../../../actions';
import './dashboard.scss';

const { Content, Footer } = Layout;

const Fallback404 = () => (
    <Content
        style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}
    >
        <div>
            <h1
                style={{
                    color: '#999',
                    fontSize: '2rem'
                }}
            >
                PÁGINA NÃO ENCONTRADA!
            </h1>
            <Icon
                style={{
                    color: '#999',
                    fontSize: '3rem'
                }}
                type="robot"
            />
        </div>
    </Content>
)
 
const DashboardPage = ({ setAccessToken }) => {
    const history = useHistory();
    const [signenId, setSignedIn] = useState(false);

    useEffect(() => {
        UserService.getUsers()
        .then(() => {
            setSignedIn(true);
            setAccessToken(localStorage.getItem('auth-access-token'));
        })
        .catch(() => {
            history.push('/signin');
        });
    }, []);

    return (
        <div className="ps-admin-page">
            {
                signenId && (
                    <>
                        <MainMenu />
                        <Layout className="ps-admin-layout">
                            <PageHeader
                                className="ps-admin-header"
                                title={(
                                    <span className="ps-admin-header-title" >
                                        INÍCIO
                                    </span>
                                )}
                                subTitle="Visualize todos os componentes da aplicação"
                            />
                            <hr className="ps-admin-divider" />
                            <Switch>
                                <Route path="/" exact={true} component={HomePage} />
                                <Route path="/noticias" exact={true} component={NewsPage} />
                                <Route path="/noticias/adicionar" exact={true} component={AddNewsPage} />
                                <Route path="/noticias/:newsId" exact={true} component={EditNewsPage} />
                                <Route path="/tags" exact={true} component={TagsPage} />
                                <Route path="/tags/adicionar" exact={true} component={AddTagPage} />
                                <Route path="/tags/:tagId" exact={true} component={EditTagPage} />
                                <Route path="/quizzes" exact={true} component={QuizzesPage} />
                                <Route path="/quizzes/adicionar" exact={true} component={AddQuizPage} />
                                <Route path="/quizzes/:quizId" exact={true} component={EditQuizPage} />
                                <Route path="/usuarios/funcoes" exact={true} component={RolesPage} />
                                <Route path="/usuarios/funcoes/adicionar" exact={true} component={AddRolePage} />
                                <Route path="/usuarios/especialidades" exact={true} component={SpecialtiesPage} />
                                <Route path="/usuarios/especialidades/adicionar" exact={true} component={AddSpecialtyPage} />
                                <Route path="/usuarios/escolaridades" exact={true} component={EducationsPage} />
                                <Route path="/usuarios/escolaridades/adicionar" exact={true} component={AddEducationPage} />
                                <Route path="/usuarios" exact={true} component={UsersPage} />
                                <Route path="*" component={Fallback404} />
                            </Switch>
                            <Footer className="ps-admin-footer">
                                EducaSangue ©{new Date().getFullYear()} | Criado e desenvolvido com <Icon type="heart" /> por <a target="_blank" href="http://lia.ufc.br/~samirchaves/" rel="noopener noreferrer">Samir Braga</a>.
                            </Footer>
                        </Layout>
                    </>
                )
            }
        </div>
    );
};

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => bindActionCreators({ setAccessToken }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(
    DashboardPage
);