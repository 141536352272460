import React, { useState, useEffect } from 'react';
import { message, Layout, Table } from "antd";
import moment from 'moment';
import UserService from '../../../../services/User';
const { Content, Header } = Layout;

const UsersPage = () => {
    const [users, setUsers] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);

    function fetchUsers(page) {
        setLoading(true);
        UserService.getUsers(page)
        .then(response => {
            setLoading(false);
            setUsers(response.data.users);
            setPageSize(response.data.page_size);
            setCurrentPage(response.data.page);
            setTotalPages(response.data.total_pages);
        });
    }

    useEffect(() => {
        fetchUsers(1);
    }, []);

    return (
        <Content className="ps-admin-content">
            <Header className="pt-3">
                <h3 className="h5 d-block">Usuários</h3>
            </Header>
            <Table
                loading={loading}
                pagination={{
                    position: 'bottom',
                    pageSize: pageSize,
                    total: totalPages * pageSize,
                    current: currentPage,
                    onChange: fetchUsers,
                    size: 'small'
                }}
                dataSource={users}
                columns={[{
                    title: 'Nome',
                    dataIndex: 'first_name',
                    key: 'first_name',
                }, {
                    title: 'Sobrenome',
                    dataIndex: 'last_name',
                    key: 'last_name',
                }, {
                    title: 'Email',
                    dataIndex: 'email',
                    key: 'email',
                }, {
                    title: 'Nascimento',
                    dataIndex: 'birthdate',
                    key: 'birthdate',
                    render(birthdate) {
                        return <span>{moment(birthdate).format('DD/MM/YYYY')}</span>
                    }
                }, {
                    title: 'Profissão',
                    dataIndex: 'role',
                    key: 'role',
                    render({ name }) {
                        return <span>{name}</span>
                    }
                }, {
                    title: 'Especialidade',
                    dataIndex: 'specialty',
                    key: 'specialty',
                    render({ name }) {
                        return <span>{name}</span>
                    }
                }, {
                    title: 'Escolaridade',
                    dataIndex: 'education',
                    key: 'education',
                    render({ description }) {
                        return <span>{description}</span>
                    }
                }]}
            />
        </Content>
    );
};

export default UsersPage;