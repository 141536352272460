import React from 'react';
import { Layout, message, Form } from "antd";
import { useHistory } from 'react-router';
import UserService from '../../../../services/User';
import SpecialtyForm from '../../../general/specialty-form';

const { Header, Content } = Layout;

const AddSpecialtyPage = () => {
    const history = useHistory();
    
    const handleSubmit = values => {
        const cancelLoading = message.loading('Criando especialidade...');

        UserService.addSpecialty(values)
        .then(() => {
            message.success('Especialidade criada com sucesso.');
            history.push('/usuarios/especialidades');
        })

        .finally(() => {
            cancelLoading();
        });
    };

    return (
        <Layout className="ps-admin-content">
            <Header>
                <span>Crie uma nova especialidade</span>
            </Header>
            <Content>
                <div className="pd-add-specialty-container">
                    <SpecialtyForm onSubmit={handleSubmit} loading={false}  />
                </div>
            </Content>
        </Layout>
    );
}
const WrappedEditSpecialtyForm = Form.create({ name: 'specialty_register' })(AddSpecialtyPage);

export default WrappedEditSpecialtyForm;


