import { SET_USER_PROPS, SET_SIGNUP_TOKEN,  SET_ACCESS_TOKEN } from './types';

export const setUserProps = userProps => ({
    type: SET_USER_PROPS,
    payload: userProps
});

export const setSignupToken = signupToken => ({
    type: SET_SIGNUP_TOKEN,
    payload: signupToken
});

export const setAccessToken = accessToken => ({
    type: SET_ACCESS_TOKEN,
    payload: accessToken
});