import React from 'react';
import { Layout, message, Form } from "antd";
import { useHistory } from 'react-router';
import UserService from '../../../../services/User';
import EducationForm from '../../../general/education-form';

const { Header, Content } = Layout;

const AddEducationPage = () => {
    const history = useHistory();
    
    const handleSubmit = values => {
        const cancelLoading = message.loading('Criando escolaridade...');

        UserService.addEducation(values)
        .then(() => {
            message.success('Escolaridade criada com sucesso.');
            history.push('/usuarios/escolaridades');
        })
        .finally(() => {
            cancelLoading();
        });
    };

    return (
        <Layout className="ps-admin-content">
            <Header>
                <span>Crie uma nova escolaridade</span>
            </Header>
            <Content>
                <div className="pd-add-education-container">
                    <EducationForm onSubmit={handleSubmit} loading={false}  />
                </div>
            </Content>
        </Layout>
    );
}
const WrappedEditEducationForm = Form.create({ name: 'education_register' })(AddEducationPage);

export default WrappedEditEducationForm;


