import React, { Component } from 'react';
import { Col, Row, Layout, List, Icon } from "antd";
import moment from 'moment';
import AdminOption from '../../../general/admin-option';
import NewsService from '../../../../services/News';
import QuizService from '../../../../services/Quiz';
import UserService from '../../../../services/User';
import TagService from '../../../../services/Tag';
import "./styles.scss";

const { Content } = Layout;

class HomePage extends Component {
    state = {
        options: [{
            title: 'QUIZZES',
            icon: 'question',
            info: count => `${count} QUIZZES CADASTRADOS`,
            route: '/quizzes',
            canAdd: true,
            responseDataField: 'quizzes',
            listData: QuizService.getAll(1),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='question' />&nbsp;
                            {item.questions.length}
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.subject}</span>
                </List.Item>
            ),
            addRoute: '/quizzes/adicionar'
        }, {
            title: 'NOTÍCIAS',
            icon: 'pic-left',
            info: count => `${count} NOTÍCIAS PUBLICADAS`,
            route: '/noticias',
            canAdd: true,
            responseDataField: 'news',
            listData: NewsService.getAll(1),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='calendar' />&nbsp;
                            {moment(item.publication_date).format('DD/MM/YY')}
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.headline}</span>
                </List.Item>
            ),
            addRoute: '/noticias/adicionar'
        }, {
            title: 'TAGS',
            icon: 'tag',
            info: count => `${count} TAGS EXISTENTES`,
            route: '/tags',
            canAdd: true,
            responseDataField: 'tags',
            listData: TagService.getAll(),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='tag' />&nbsp;
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.name}</span>
                </List.Item>
            ),
            addRoute: '/tags/adicionar'
        }, {
            title: 'FUNÇÕES',
            icon: 'idcard',
            info: count => `${count} FUNÇÕES CADASTRADAS`,
            route: '/usuarios/funcoes',
            canAdd: true,
            responseDataField: 'roles',
            listData: UserService.getRoles(),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='idcard' />&nbsp;
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.name}</span>
                </List.Item>
            ),
            addRoute: '/usuarios/funcoes/adicionar'
        }, {
            title: 'ESPECIALIDADES',
            icon: 'reconciliation',
            info: count => `${count} ESPECIALIDADES CADASTRADAS`,
            route: '/usuarios/especialidades',
            canAdd: true,
            responseDataField: 'specialties',
            listData: UserService.getSpecialties(),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='reconciliation' />&nbsp;
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.name}</span>
                </List.Item>
            ),
            addRoute: '/usuarios/especialidades/adicionar'
        }, {
            title: 'ESCOLARIDADES',
            icon: 'read',
            info: count => `${count} ESCOLARIDADES CADASTRADAS`,
            route: '/usuarios/escolaridades',
            canAdd: true,
            responseDataField: 'educations',
            listData: UserService.getEducations(),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='read' />&nbsp;
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.description}</span>
                </List.Item>
            ),
            addRoute: '/usuarios/escolaridades/adicionar'
        }, {
        //     title: 'UTILIDADES',
        //     icon: 'medicine-box',
        //     info: '9 UTILIDADES EXISTENTES',
        //     route: '/utilidades',
        //     canAdd: false,
        //     listData: [{
        //         text: 'Anim voluptate mollit mollit anim nisi est nulla et.',
        //         amount: 32
        //     }],
        //     listIcon: 'calculator'
        // }, {
            title: 'USUÁRIOS',
            icon: 'user',
            info: count => `${count} USUÁRIOS EXISTENTES`,
            route: '/usuarios',
            canAdd: false,
            responseDataField: 'users',
            listData: UserService.getUsers(),
            listItemRender: item => (
                <List.Item
                    className="ps-admin-list-item"
                    key={item.id}
                    extra={
                        <span className="ps-admin-option-icon">
                            <Icon type='user' />&nbsp;
                        </span>
                    }
                >
                    <span className='text-truncate d-block'>{item.first_name} {item.last_name}</span>
                </List.Item>
            ),
            // size: 24
        }]
    }

    render() {
        return (
            <Content >
                <Row gutter={16}>
                    {this.state.options.map(option => (
                        <Col span={option.size || 8}>
                            <AdminOption {...option} />
                        </Col>
                    ))}
                </Row>
            </Content>
        );
    }
};

export default HomePage;