import React from 'react';
import { Form, Button, Input } from "antd";

const RoleForm = ({ currentRole, loading, form, onSubmit }) => {
    const { getFieldDecorator } = form;

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 16 },
        },
    };
    
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 12,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 5,
            },
        },
    };
        
    const handleSubmit = e => {
        e.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                onSubmit(values);
            }
        });
    };

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Form.Item label="Nome">
                {getFieldDecorator('name', {
                    rules: [{
                        required: true,
                        message: 'Nome obrigatório!',
                    }],
                    initialValue: currentRole?.name
                })(<Input />)}
            </Form.Item>
            <Form.Item label="Descrição">
                {getFieldDecorator('description', {
                    rules: [{
                        required: true,
                        message: 'Descrição obrigatória!',
                    }],
                    initialValue: currentRole?.description
                })(<Input.TextArea />)}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button disabled={loading} type="primary" htmlType="submit">
                    Salvar
                </Button>
            </Form.Item>
        </Form>
    );
}

const WrappedRoleForm = Form.create({ name: 'role' })(RoleForm);
export default WrappedRoleForm;
