import React, { useState } from 'react';
import { Menu, Icon, Layout, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import LogoImage from '../../../assets/logo.png';
import data from "../../../data";
import AdminService from '../../../services/Admin';
import './menu.scss';

const { SubMenu } = Menu;
const { Sider } = Layout;

const MainMenu = () => {
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);

    return (
        <Sider
            width="300"
            collapsible
            collapsed={collapsed}
            onCollapse={setCollapsed}
            theme='light'
            className="ps-main-menu-wrapper"
        >
            <div className="ps-main-menu-brand">
                <img
                    width={180}
                    style={{
                        transform: `scale(${collapsed ? 0.6 : 1})`
                    }}
                    src={LogoImage}
                    alt="Logo - EducaSangue"
                />
            </div>
            <Menu
                defaultSelectedKeys={("/" + window.location.pathname.split('/')[1]).repeat(2)}
                mode="inline"
            >
                {data.pages.map(page => (
                    page.subitems === undefined ? (
                        <Menu.Item key={page.href.repeat(2)}>
                            <Link to={page.href}>
                                <Icon type={page.icon} />
                                <span>{page.label}</span>
                            </Link>
                        </Menu.Item>
                    ) : (
                        <SubMenu
                            key={page.href.repeat(2)}
                            title={
                                <span>
                                    <Icon type={page.icon} /><span>{page.label}</span>
                                </span>
                            }
                        >
                            {page.subitems.map(subitem => (
                                <Menu.Item key={subitem.href.repeat(2)}>
                                    <Link to={subitem.href}>
                                        <Icon type={subitem.icon} />
                                        <span>{subitem.label}</span>
                                    </Link>
                                </Menu.Item>
                            ))}
                        </SubMenu>
                    )
                ))}
                <Menu.Item key='logout'>
                    <div
                        onClick={() => {
                            AdminService.signOut()
                            .then(() => {
                                history.push('/signin');
                            })
                            .catch(() => {
                                message.error('Não foi possível sair, por favor, tente novamente.');
                            });
                        }}
                    >
                        <Icon type={'logout'} />
                        <span>Sair</span>
                    </div>
                </Menu.Item>
            </Menu>
        </Sider>
    );
}
export default MainMenu;