import React, { useEffect, useState, useRef } from 'react';
import { message, Form, Button, Input, Select, DatePicker, Icon, Upload } from "antd";
import ReactQuill from 'react-quill';
import moment from 'moment';
import { connect } from 'react-redux';
import TagService from '../../../services/Tag';
import { baseUrlHost } from '../../../services/api';

const { Option } = Select;

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('A imagens devem ter o formato JPG ou PNG.');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('O tamanho da imagem deve ser, no máximo, 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

const NewsForm = ({ currentNews, loading, form, onSubmit, accessToken }) => {
    const [imgLoading, setImgLoading] = useState(currentNews ? true : false);
    const [imgUrl, setImgUrl] = useState('');
    const [tags, setTags] = useState([]);
    const editor = useRef();
    const { getFieldDecorator } = form;

    useEffect(() => {
        TagService.getAll()
        .then(({ data }) => {
            setTags(data.tags);
        })

    }, []);

    useEffect(() => {
        setImgUrl(currentNews?.image);
    }, [currentNews]);

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 12 },
            sm: { span: 16 },
        },
    };
    
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 12,
                offset: 0,
            },
            sm: {
                span: 16,
                offset: 5,
            },
        },
    };

    const handleChange = info => {
        if (info.file.status === 'uploading') {
            setImgLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            setImgUrl(`${baseUrlHost}/api/upload/${info.file.response.file.storedName}`);
            setImgLoading(false);
        }
    };
        
    const handleSubmit = e => {
        e.preventDefault();

        form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                const update = {
                    ...values,
                    publication_date: values.publication_date.toString()
                };
    
                if (values.image) {
                    update.image = imgUrl;
                }

                update.body = update.body
                .replace(/<\/p><p>/g, '<br>')
                .replace(/<br><br>/g, '<br>');
                
                onSubmit(update);
            }
        });
    };

    const uploadButton = (
      <div>
        <Icon type={imgLoading ? 'loading' : 'plus'} />
        <div className="ant-upload-text">Upload</div>
      </div>
    );

    return (
        <Form {...formItemLayout} onSubmit={handleSubmit}>
            <Form.Item label="Título">
                {getFieldDecorator('headline', {
                    rules: [{
                        required: true,
                        message: 'Título obrigatório!',
                    }],
                    initialValue: currentNews?.headline
                })(<Input />)}
            </Form.Item>
            <Form.Item label="Imagem Principal">
                {getFieldDecorator('image', {})(
                    <Upload
                        listType="picture"
                        className="image-uploader"
                        showUploadList={false}
                        action={`${baseUrlHost}/api/upload`}
                        onChange={handleChange}
                        beforeUpload={beforeUpload}
                        headers={{
                            'x-access-token': accessToken
                        }}
                    >
                        {
                            imgUrl ? (
                                <img
                                    src={imgUrl}
                                    alt={currentNews?.headline}
                                    style={{ maxWidth: '100%', maxHeight: 300 }}
                                /> 
                            ) : uploadButton
                        }
                    </Upload>
                )}
            </Form.Item>
            <Form.Item label="Data de Publicação">
                {getFieldDecorator('publication_date', {
                    rules: [{
                        required: true,
                        message: 'Campo obrigatório!',
                    }],
                    initialValue: moment(currentNews?.publication_date)
                })(
                    <DatePicker format='DD/MM/YYYY' />
                )}
            </Form.Item>
            <Form.Item label="Conteúdo Principal">
                {getFieldDecorator('body', {
                    initialValue: currentNews?.body || '',
                    rules: [{
                        required: true,
                        message: 'Campo obrigatório!',
                    }]
                })(
                    <ReactQuill ref={editor} />
                )}
            </Form.Item>
            <Form.Item label="Tags">
                {getFieldDecorator('tags', {
                    initialValue: currentNews?.tags?.map(tag => tag.id)
                })(
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Adicione tags à notícia"
                        optionLabelProp="label"
                    >
                        {tags.map(tag => (
                            <Option key={tag.id} value={tag.id} label={tag.name}>
                                {tag.name}
                            </Option>
                        ))}
                    </Select>
                )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
                <Button disabled={loading} type="primary" htmlType="submit">
                    Salvar
                </Button>
            </Form.Item>
        </Form>
    );
}
const WrappedEditNewsForm = Form.create({ name: 'news' })(NewsForm);

const mapStateToProps = state => ({
    accessToken: state.signinState.access_token
});

export default connect(mapStateToProps)(WrappedEditNewsForm);
