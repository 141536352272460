import React from 'react';
import { Layout, message, Form } from "antd";
import { useHistory } from 'react-router';
import UserService from '../../../../services/User';
import RoleForm from '../../../general/role-form';

const { Header, Content } = Layout;

const AddRolePage = () => {
    const history = useHistory();
    
    const handleSubmit = values => {
        const cancelLoading = message.loading('Criando profissão...');

        UserService.addRole(values)
        .then(() => {
            message.success('Profissão criada com sucesso.');
            history.push('/usuarios/funcoes');
        })

        .finally(() => {
            cancelLoading();
        });
    };

    return (
        <Layout className="ps-admin-content">
            <Header>
                <span>Crie uma nova profissão</span>
            </Header>
            <Content>
                <div className="pd-add-role-container">
                    <RoleForm onSubmit={handleSubmit} loading={false}  />
                </div>
            </Content>
        </Layout>
    );
}
const WrappedEditRoleForm = Form.create({ name: 'role_register' })(AddRolePage);

export default WrappedEditRoleForm;


