import React, { useEffect, useState } from 'react';
import { Layout, message, Skeleton, Form } from "antd";
import { useParams } from 'react-router';
import TagService from '../../../../services/Tag';
import TagForm from '../../../general/tag-form';

const { Header, Content } = Layout;

const EditTagPage = () => {
    const [loading, setLoading] = useState(true);
    const [currentTag, setCurrentTag] = useState({});
    const { tagId } = useParams();

    useEffect(() => {
        if (tagId) {
            const cancelLoading = message.loading('Carregando tag...');
            TagService.get(tagId)
            .then(({ data }) => {
                setCurrentTag(data.tag);
            })
            .finally(() => {
                cancelLoading();
                setLoading(false);
            });
        }
    }, [tagId]);

    
    const handleSubmit = values => {
        const cancelLoading = message.loading('Salvando alterações...');

        TagService.update(currentTag.id, values)
        .then(() => {
            message.success('Tag atualizada com sucesso');
        })

        .finally(() => {
            cancelLoading();
        });
    };

    return (
        <Layout className="ps-admin-content">
            <Header>
              <Skeleton loading={loading} title paragraph={false}>
                <span>{currentTag.name}</span>
              </Skeleton>
            </Header>
            <Content>
                <div className="pd-add-tag-container">
                    <TagForm onSubmit={handleSubmit} loading={loading} currentTag={currentTag} />
                </div>
            </Content>
        </Layout>
    );
}
const WrappedEditTagForm = Form.create({ name: 'register' })(EditTagPage);

export default WrappedEditTagForm;
