import React, { useState, useEffect } from 'react';
import { Layout, message, List, Popconfirm, Tag, Tooltip, Input, Button, Icon } from "antd";
import { Link } from 'react-router-dom';
import moment from 'moment';
import NewsService from '../../../../services/News';

const { Content, Header } = Layout;
const { Search } = Input;

function _stripTagsAndLimit(string) {
    return string.replace(/(<([^>]+)>)/ig,"").split(' ').slice(0, 30).join(' ') + '...';
}

const NewsPage = () => {
    const [news, setNews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loading, setLoading] = useState(true);
    const [totalPages, setTotalPages] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    function fetchNews(page) {
        setLoading(true);
        NewsService.getAll(page)
        .then(response => {
            setLoading(false);
            setNews(response.data.news);
            setPageSize(response.data.page_size);
            setCurrentPage(response.data.page);
            setTotalPages(response.data.total_pages);
        });
    }

    function removeNews(newsId) {
        const cancelLoading = message.loading('Removendo notícia...');
        NewsService.remove(newsId)
        .then(response => {
            cancelLoading();
            fetchNews(currentPage);
            message.success(response.data.message);
        })
        .finally(() => {
            cancelLoading();
        });
    };

    useEffect(() => {
        fetchNews(1);
    }, []);

    useEffect(() => {
        if (/\w/g.test(searchQuery)) {
            setLoading(true);
            NewsService.search(searchQuery, currentPage)
            .then(response => {
                setLoading(false);
                setNews(response.data.news);
                setPageSize(response.data.page_size);
                setCurrentPage(response.data.page);
                setTotalPages(response.data.total_pages);
            });
        }
    }, [searchQuery, currentPage]);

    return (
        <Content className="ps-admin-content">
            <Header className="pt-3">
                <h3 className="h5 d-block">Notícias</h3>
                <Search
                    className="px-3 w-100"
                    onSearch={setSearchQuery}
                    enterButton 
                />
                <Link className="d-block" to="/noticias/adicionar">
                    <Button type="primary">
                        Adicionar
                        <Icon type="plus" />
                    </Button>
                </Link>
            </Header>
            <List
                itemLayout="horizontal"
                dataSource={news}
                renderItem={item => (
                    <List.Item
                        actions={[
                            <Link to={`/noticias/${item.id}`}>
                                <Button type='link'>Editar</Button>
                            </Link>,
                            <Popconfirm
                              title="Deseja mesmo remover essa notícia?"
                              onConfirm={() => removeNews(item.id)}
                              okText="Sim"
                              cancelText="Não"
                            >
                              <Button type='link'>Remover</Button>
                            </Popconfirm>,
                        ]}
                    >
                        <img
                            className="mr-3"
                            src={item.image}
                            alt={`Imagem Notícia "${item.headline}"`}
                            style={{
                                width: '120px',
                                height: '100px',
                                objectFit: 'cover'
                            }}
                        />
                        <List.Item.Meta
                            title={item.headline}
                            description={(
                                <div>
                                    <p>{_stripTagsAndLimit(item.body)}</p>
                                    <div className="d-flex">
                                        <div className="pr-4">
                                            <Icon type='calendar' />&nbsp;&nbsp;
                                            {moment(item.publication_date).format('DD/MM/YYYY')}
                                        </div>
                                        <div>
                                            {item.tags && item.tags.map(tag => (
                                                <Tooltip key={tag.id} title={tag.description}>
                                                    <Tag color='#b44c4d'> 
                                                        {tag.name}
                                                    </Tag>
                                                </Tooltip>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            )}
                        />
                    </List.Item>
                )}
                loading={loading}
                pagination={{
                    position: 'top',
                    pageSize: pageSize,
                    total: totalPages * pageSize,
                    current: currentPage,
                    onChange: fetchNews,
                    size: 'small'
                }}
            />
        </Content>
    );
}

export default NewsPage;
    