import React from 'react';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Provider } from 'react-redux';
import DashboardPage from './components/pages/dashboard/index';
import SigninPage from './components/pages/signin';
import store from './store';
import './App.css';

const App = () => (
  <BrowserRouter>
    <Provider store={store}>
      <div className="ps-app">
        <Switch>
          <Route path="/signin">
            <SigninPage />
          </Route>
          <Route path="/">
            <DashboardPage />
          </Route>
        </Switch>
      </div>
    </Provider>
  </BrowserRouter>
);

export default App;
