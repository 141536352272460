import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { message, Layout, Table, Popconfirm, Button, Icon } from "antd";
import UserService from '../../../../services/User';
const { Content, Header } = Layout;

const SpecialtiesPage = () => {
    const [specialties, setSpecialties] = useState([]);
    const [loading, setLoading] = useState(true);

    function fetchSpecialties() {
        setLoading(true);
        UserService.getSpecialties()
        .then(response => {
            setLoading(false);
            setSpecialties(response.data.specialties);
        });
    }

    function removeSpecialty(specialtyId) {
        const cancelLoading = message.loading('Removendo especialidade...');
        UserService.removeSpecialty(specialtyId)
        .then(() => {
            fetchSpecialties();
            message.success('Profissão removida com sucesso.');
        })
        .finally(() => {
            cancelLoading();
        });
    };

    useEffect(() => {
        fetchSpecialties();
    }, []);

    return (
        <Content className="ps-admin-content">
            <Header className="pt-3">
                <h3 className="h5 d-block">Especialidades</h3>
                <Link className="d-block" to="/usuarios/especialidades/adicionar">
                    <Button type="primary">
                        Adicionar
                        <Icon type="plus" />
                    </Button>
                </Link>
            </Header>
            <Table
                loading={loading}
                pagination={false}
                dataSource={specialties}
                columns={[{
                    title: 'Nome',
                    dataIndex: 'name',
                    key: 'name',
                }, {
                    title: 'Descrição',
                    dataIndex: 'description',
                    key: 'description',
                }, {
                  title: 'Ações',
                  key: 'action',
                  render: (text, item) => (
                    <Popconfirm
                      title="Deseja mesmo remover essa especialidade?"
                      onConfirm={() => removeSpecialty(item.id)}
                      okText="Sim"
                      cancelText="Não"
                    >
                      <Button type='link'>Remover</Button>
                    </Popconfirm>
                  )
                }]}
            />
        </Content>
    );
};

export default SpecialtiesPage;